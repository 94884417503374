<template>
  <div id="content" class="app-content">
    <!-- BEGIN container -->
    <b-container>
      <!-- BEGIN row -->
      <b-row class="justify-content-center">
        <!-- BEGIN col-10 -->
        <b-col xl="12">
          <!-- BEGIN row -->
          <b-row>
            <!-- BEGIN col-9 -->
            <b-col xl="12">
              <b-breadcrumb>
                <b-breadcrumb-item :to="{ name: 'products' }"> PRODUCTS </b-breadcrumb-item>
                <b-breadcrumb-item v-if="isEditPage" active>
                  {{ name.toUpperCase() }}
                </b-breadcrumb-item>
                <b-breadcrumb-item active>
                  {{ breadcrumbTitle }}
                </b-breadcrumb-item>
              </b-breadcrumb>

              <b-tabs content-class="mt-3">
                <b-tab title="Product" :active="$route.name === 'products.edit'" @click="tabGoTo(links.edit)">
                  <!-- page header -->
                  <h1 class="page-header">{{ pageTitle }}</h1>
                  <hr />

                  <validation-observer ref="productsForm">
                    <form @submit.prevent="submitFormData">
                      <b-row>
                        <b-col cols="8">
                          <h4><i class="far fa-heading fa-fw" /> Title</h4>
                          <b-card>
                            <validation-provider vid="name" name="Name" v-slot="context" rules="required">
                              <b-form-group label="Name">
                                <b-form-input
                                  id="formNameField"
                                  placeholder="e.g Mountain Hiking"
                                  v-model="name"
                                  :state="getValidationState(context)"
                                  aria-describedby="formNameFieldError"
                                  v-bind="context.ariaInput"
                                  :disabled="$cannot('product.create')"
                                />

                                <b-form-invalid-feedback id="formNameFieldError" v-bind="context.ariaMsg">
                                  {{ context.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-card>

                          <hr />

                          <h4><i class="far fa-file-alt fa-fw" /> Description</h4>
                          <b-card>
                            <validation-provider vid="lead" name="Lead Information" v-slot="context" rules="required">
                              <b-form-group label="Lead" label-for="formLeadField">
                                <b-textarea
                                  id="formLeadField"
                                  v-model="lead"
                                  placeholder="e.g A wonderful location"
                                  rows="3"
                                  max-rows="6"
                                  :state="getValidationState(context)"
                                  :disabled="$cannot('product.create')"
                                  aria-describedby="formNameFieldError"
                                  v-bind="context.ariaInput"
                                />

                                <b-form-invalid-feedback id="formNameFieldError" v-bind="context.ariaMsg">{{
                                  context.errors[0]
                                }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>

                            <validation-provider vid="content" name="Product Content" v-slot="context" rules="required">
                              <b-form-group label="Content" label-for="formContentField">
                                <froala v-model="content" />
                                <small
                                  id="formCategoryFieldError"
                                  v-if="context.errors.length"
                                  v-bind="context.ariaMsg"
                                  class="text-danger form-text"
                                  >{{ context.errors[0] }}</small
                                >
                              </b-form-group>
                            </validation-provider>

                            <hr />

                            <div class="mb-2">Bullet Points</div>

                            <template v-if="bullet_points">
                              <b-row v-for="(point, index) in bullet_points" :key="index" class="mb-3">
                                <b-col cols="6">
                                  <keep-alive>
                                    <validation-provider name="Bullet points" v-slot="context" :vid="`bullet_points.${index}.content`">
                                      <b-input-group>
                                        <b-form-input
                                          placeholder="e.g Wonderful tracks"
                                          v-model="bullet_points[index].content"
                                          :state="getValidationState(context)"
                                          :aria-describedby="`formBulletPonts${index}FieldError`"
                                          :disabled="$cannot('product.create')"
                                          style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                          v-bind="context.ariaInput"
                                        />

                                        <template #append>
                                          <b-button
                                            variant="danger"
                                            @click="() => removeBulletPoint(index)"
                                            :disabled="$cannot('product.create')"
                                          >
                                            <strong>&times;</strong>
                                          </b-button>
                                        </template>
                                      </b-input-group>

                                      <b-form-invalid-feedback :state="getValidationState(context)" v-bind="context.ariaMsg">
                                        {{context.errors[0]?.replace(`bullet_points.${index}.content`, 'content') }}
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </keep-alive>
                                </b-col>
                              </b-row>
                            </template>

                            <b-button
                              variant="light"
                              size="sm"
                              @click="addEmptyBulletPoint"
                              :disabled="$cannot('product.create')"
                            >
                              Add Point
                            </b-button>
                          </b-card>

                          <hr />

                          <div v-if="type === 'experience_cash_voucher' || type === 'themed'">
                            <h4><i class="far fa-text fa-fw" /> Cash Voucher Data</h4>
                            <b-card>
                                <template>
                                    <label>Description</label>
                                    <b-form-input
                                        v-if="type === 'themed'"
                                        placeholder="Description"
                                        v-model="experience_cash_voucher_description"
                                        style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                    />

                                    <hr>

                                    <label>Amount Description</label>
                                    <b-form-input
                                        v-if="type === 'themed'"
                                        placeholder="Amount Description"
                                        v-model="experience_cash_voucher_amount_description"
                                        style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                    />

                                    <hr>
                                    <label>Themed Error Message</label>
                                    <b-form-input
                                        v-if="type === 'themed'"
                                        placeholder="Themed Error Message"
                                        v-model="experience_cash_voucher_error_message"
                                        style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                    />

                                    <hr>

                                    <div v-if="experience_cash_voucher_data.length">
                                        <b-row v-for="(cash_voucher_data, index) in experience_cash_voucher_data" :key="index" class="mb-3">
                                            <b-col cols="12">
                                                <validation-provider name="Experience Cash Voucher Data" v-slot="context" :vid="`experience_cash_voucher_data.${index}.content`">
                                                    <b-input-group>
                                                        <b-form-input
                                                            placeholder="Description"
                                                            v-model="experience_cash_voucher_data[index].name"
                                                            style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                                            v-bind="context.ariaInput"
                                                        />
                                                        <b-form-input
                                                            placeholder="Price"
                                                            type="number"
                                                            v-model="experience_cash_voucher_data[index].price"
                                                            style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                                            v-bind="context.ariaInput"
                                                        />

                                                        <template #append>
                                                            <strong @click="() => removeExperienceCashVoucherData(index)" class="text-red h6 mt-2 ml-1" style="font-size:18px; cursor: pointer;">&times;</strong>
                                                        </template>
                                                    </b-input-group>
                                                </validation-provider>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </template>

                                <b-button
                                    variant="light"
                                    size="sm"
                                    @click="addExperienceCashVoucherData"
                                >
                                    Add Cash Voucher Data
                                </b-button>
                            </b-card>
                          </div>

                          <div v-if="type === 'hotel'">
                              <h4><i class="far fa-text fa-fw" /> Hotel Content</h4>
                              <b-card>
                                  <template>
                                      <b-col cols="8" class="mb-3">
                                          <keep-alive>
                                              <validation-provider v-slot="context">
                                                  <b-input-group>
                                                      <b-form-input
                                                          placeholder="Count"
                                                          v-model="hotel_count"
                                                          style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                                          v-bind="context.ariaInput"
                                                      />

                                                      <b-form-checkbox
                                                          v-model="hotel_map_only"
                                                          class="mt-1 ml-2"
                                                          v-bind="context.ariaInput">
                                                          Hotel Map Only
                                                      </b-form-checkbox>
                                                  </b-input-group>
                                              </validation-provider>
                                          </keep-alive>
                                      </b-col>

                                      <div v-if="hotel_content.length">
                                        <b-row v-for="(content, index) in hotel_content" :key="index" class="mb-3">
                                          <b-col cols="12">
                                              <validation-provider name="Hotel Content" v-slot="context" :vid="`hotel_content.${index}.content`">
                                                  <b-input-group>
                                                      <b-form-input
                                                          placeholder="Name"
                                                          v-model="hotel_content[index].name"
                                                          style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                                          v-bind="context.ariaInput"
                                                      />
                                                      <b-form-input
                                                          placeholder="Prizren, Kosova"
                                                          v-model="hotel_content[index].location"
                                                          style="border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                                          v-bind="context.ariaInput"
                                                      />
                                                      <b-form-input
                                                          v-model="hotel_content[index].coordinates"
                                                          v-bind="context.ariaInput"
                                                          placeholder="42.221041, 20.751225"
                                                      />

                                                      <div style="width: 150px;">
                                                          <b-form-file
                                                              @click="$refs.file.click()"
                                                              variant="primary"
                                                              class="btn"
                                                              type="file"
                                                              ref="file"
                                                              @change="pick($event, index)"
                                                              v-model="hotel_content[index].image"
                                                              accept="image/*"
                                                          />
                                                          <small v-if="hotel_content[index].image">
                                                              <a :href="hotel_content[index].image" target="_blank">
                                                                  <i class="fas fa-download"/> download current
                                                              </a>
                                                          </small>
                                                      </div>

                                                      <template #append>
                                                          <strong @click="() => removeHotelContent(index)" class="text-red h6 mt-2 ml-1" style="font-size:18px; cursor: pointer;">&times;</strong>
                                                      </template>
                                                  </b-input-group>

                                                  <b-form-invalid-feedback :state="getValidationState(context)" v-bind="context.ariaMsg">
                                                      {{context.errors[0]?.replace(`hotel_content.${index}.content`, 'content') }}
                                                  </b-form-invalid-feedback>
                                              </validation-provider>
                                          </b-col>
                                      </b-row>
                                      </div>
                                  </template>

                                  <b-button
                                      variant="light"
                                      size="sm"
                                      @click="addEmptyHotelContent"
                                  >
                                      Add Hotel Content
                                  </b-button>
                              </b-card>
                          </div>

                          <hr />

                          <h4><i class="far fa-photo-video fa-fw" /> Embeds</h4>
                          <b-card>
                            <b-form-group label="Video Content" label-for="formVideoContentField">
                              <b-form-text id="formVideoContentFieldDesc">
                                Paste in an embed from YouTube of similar sites.
                              </b-form-text>
                              <b-textarea
                                id="formVideoContentField"
                                v-model="video_content"
                                placeholder
                                rows="3"
                                max-rows="6"
                                aria-describedby="formVideoContentFieldDesc"
                                :disabled="$cannot('product.create')"
                              />
                            </b-form-group>
                          </b-card>

                          <hr />

                          <h4><i class="far fa-details" /> Default About</h4>
                          <b-card>
                            <b-form-group label="Participants Text">
                              <b-form-input v-model="participants_text" placeholder="e.g wear hiking boots" />
                            </b-form-group>

                            <b-form-group label="When">
                              <b-form-input v-model="when" placeholder="e.g At sunrise" />
                            </b-form-group>

                            <b-form-group label="Duration">
                              <b-form-input v-model="duration" placeholder="e.g 10 hours" />
                            </b-form-group>

                            <b-form-group label="Requirements">
                              <b-form-input v-model="requirements" placeholder="e.g come with a physical report" />
                              <b-form-text>{{ 225 - requirements.length }} / 225 characaters remaing</b-form-text>
                            </b-form-group>

                            <b-form-group label="Min Age">
                              <b-form-input type="number" v-model="min_age" placeholder="e.g 18" />
                            </b-form-group>
                          </b-card>
                          <hr />
                          <div v-if="type === 'digital'">
                            <h4><i class="far fa-heading fa-fw" /> Live Settings</h4>
                            <b-card>
                              <validation-provider
                                vid="how_it_works"
                                name="How it works"
                                v-slot="context"
                                rules="required"
                              >
                                <b-form-group label="How it works">
                                  <froala
                                    id="formHowItWorksField"
                                    placeholder="e.g you need a big bowl"
                                    v-model="how_it_works"
                                    :state="getValidationState(context)"
                                    aria-describedby="formHowItWorksFieldError"
                                    v-bind="context.ariaInput"
                                    :disabled="$cannot('product.create')"
                                  />

                                  <b-form-invalid-feedback id="formHowItWorksFieldError" v-bind="context.ariaMsg">
                                    {{ context.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>

                              <b-form-group label="Shopping List">
                                <draggable v-model="shopping_list" handle=".handle">
                                  <b-row v-for="(item, index) in shopping_list" :key="index" class="mb-2">
                                    <b-col cols="auto">
                                      <i class="fa fa-align-justify handle" />
                                    </b-col>
                                    <b-col cols="8">
                                      <validation-provider name="Name" v-slot="context">
                                        <b-form-input
                                          placeholder="Name"
                                          v-model="shopping_list[index].name"
                                          :state="getValidationState(context)"
                                          v-bind="context.ariaInput"
                                          :disabled="$cannot('product.create')"
                                        />

                                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                                          {{ context.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>
                                    <b-col>
                                      <validation-provider name="Price" v-slot="context">
                                        <b-form-input
                                          placeholder="Price"
                                          v-model="shopping_list[index].price"
                                          :state="getValidationState(context)"
                                          v-bind="context.ariaInput"
                                          :disabled="$cannot('product.create')"
                                        />

                                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                                          {{ context.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>
                                    <b-col cols="auto" v-if="shopping_list.length > 1">
                                      <b-button
                                        variant="light"
                                        @click="removeListItem(shopping_list, index, defaultShoppingList)"
                                      >
                                        &times;
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                </draggable>
                                <b-row align-h="end">
                                  <b-col cols="auto">
                                    <b-button variant="link" @click="addListItem(shopping_list, defaultShoppingList)">
                                      Add Row
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-form-group>

                              <validation-provider
                                vid="what_is_learned_title"
                                name="What they're learning title"
                                v-slot="context"
                              >
                                <b-form-group label="What they're learning title">
                                  <b-form-input
                                    id="formWhatTheyLearningTitle"
                                    placeholder="e.g how to make a casserole"
                                    v-model="what_is_learned_title"
                                    :state="getValidationState(context)"
                                    aria-describedby="formWhatTheyLearningTitleError"
                                    v-bind="context.ariaInput"
                                    :disabled="$cannot('product.create')"
                                  />

                                  <b-form-invalid-feedback id="formWhatTheyLearningTitleError" v-bind="context.ariaMsg">
                                    {{ context.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>

                              <b-form-group label="What they're learning content">
                                <draggable v-model="what_is_learned" handle=".handle">
                                  <b-row v-for="(item, index) in what_is_learned" :key="index" class="mb-2">
                                    <b-col cols="auto">
                                      <i class="fa fa-align-justify handle" />
                                    </b-col>
                                    <b-col>
                                      <validation-provider name="Title" v-slot="context">
                                        <b-form-input
                                          v-model="what_is_learned[index].title"
                                          placeholder="Title"
                                          :state="getValidationState(context)"
                                          v-bind="context.ariaInput"
                                          :disabled="$cannot('product.create')"
                                        />

                                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                                          {{ context.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>
                                    <b-col cols="auto" v-if="what_is_learned.length > 1">
                                      <b-button
                                        variant="light"
                                        @click="removeListItem(what_is_learned, index, defaultWhatIsLearned)"
                                      >
                                        &times;
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                </draggable>
                                <b-row align-h="end">
                                  <b-col cols="auto">
                                    <b-button
                                      variant="link"
                                      @click="addListItem(what_is_learned, defaultWhatIsLearned)"
                                    >
                                      Add Row
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-form-group>

                              <b-form-group label="What's included">
                                <draggable v-model="whats_included" handle=".handle">
                                  <b-row v-for="(item, index) in whats_included" :key="index" class="mb-2">
                                    <b-col cols="auto">
                                      <i class="fa fa-align-justify handle" />
                                    </b-col>
                                    <b-col cols="auto">
                                      <c-img
                                        :src="getIcon(item.icon)"
                                        width="35"
                                        style="cursor: pointer"
                                        @click="$bvModal.show(`whatLearnedIcon${index}`)"
                                      />

                                      <b-modal :id="`whatLearnedIcon${index}`" title="Choose icon" ok-only>
                                        <b-row>
                                          <b-col
                                            v-for="(icon, id) in whatsIncludedIcons"
                                            :key="id"
                                            cols="3"
                                            class="mb-2"
                                            style="cursor: pointer"
                                            @click="updateWhatLearnedIcon(index, icon)"
                                          >
                                            <div
                                              class="d-flex flex-column justify-content-center align-items-center h-64 border"
                                              :class="[item.icon === icon ? 'border-primary' : 'border-secondary']"
                                              style="min-height: 74px; border-width: 2px !important"
                                            >
                                              <c-img :src="getIcon(icon)" width="50" class="img-center" />
                                            </div>
                                            <p class="text-center">{{ icon }}</p>
                                          </b-col>
                                        </b-row>
                                      </b-modal>
                                    </b-col>

                                    <b-col>
                                      <validation-provider name="Name" v-slot="context">
                                        <b-form-input
                                          placeholder="Title"
                                          v-model="whats_included[index].title"
                                          :state="getValidationState(context)"
                                          v-bind="context.ariaInput"
                                          :disabled="$cannot('product.create')"
                                        />

                                        <b-form-invalid-feedback v-bind="context.ariaMsg">
                                          {{ context.errors[0] }}
                                        </b-form-invalid-feedback>
                                      </validation-provider>
                                    </b-col>

                                    <b-col cols="auto" v-if="whats_included.length > 1">
                                      <b-button
                                        variant="light"
                                        @click="removeListItem(whats_included, index, defaultWhatsIncluded)"
                                      >
                                        &times;
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                </draggable>
                                <b-row align-h="end">
                                  <b-col cols="auto">
                                    <b-button variant="link" @click="addListItem(whats_included, defaultWhatsIncluded)">
                                      Add Row
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-form-group>
                            </b-card>
                          </div>
                          <hr />
                          <h4><i class="far fa-heading fa-fw" /> SEO</h4>
                          <b-card>
                            <b-form-group label="Meta Title">
                              <b-form-input
                                placeholder="e.g you need a big bowl"
                                v-model="seo.meta_title"
                                :disabled="$cannot('product.create')"
                              />
                            </b-form-group>

                            <b-form-group label="Meta Description">
                              <b-form-textarea
                                placeholder="e.g ..."
                                v-model="seo.meta_description"
                                :disabled="$cannot('product.create')"
                              />
                              <b-form-text
                                >{{ 225 - seo.meta_description.length }} / 225 characaters remaing</b-form-text
                              >
                            </b-form-group>

                            <b-form-group label="No Index">
                              <b-form-checkbox v-model="seo.no_index" :disabled="$cannot('product.create')">
                                Enable No-Index
                              </b-form-checkbox>
                            </b-form-group>
                          </b-card>
                        </b-col>

                        <b-col cols="4">
                          <b-button variant="primary" block :disabled="$cannot('product.create')" type="submit">
                            {{ buttonText }}
                            <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
                          </b-button>
                          <hr />
                          <h4><i class="far fa-heading fa-fw" /> Type</h4>
                          <b-card>
                            <validation-provider vid="type" name="Type" v-slot="context" rules="required">
                              <b-form-group label="Type">
                                <b-form-select
                                  id="formProductTypeField"
                                  v-model="type"
                                  :options="[
                                    { value: 'product', text: 'Product' },
                                    { value: 'digital', text: 'Digital' },
                                    { value: 'hotel', text: 'Hotel' },
                                    { value: 'themed', text: 'Themed' },
                                    { value: 'experience_cash_voucher', text: 'Experience Cash Voucher' },
                                  ]"
                                  :state="getValidationState(context)"
                                  aria-describedby="formProductTypeFieldError"
                                  v-bind="context.ariaInput"
                                  :disabled="$cannot('product.create')"
                                />

                                <b-form-invalid-feedback id="formProductTypeFieldError" v-bind="context.ariaMsg">
                                  {{ context.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-card>

                          <div class="mt-3">
                            <settings-group title="Climate" icon="fas fa-leaf">
                              <setting-toggle title="Climate friendly" :status="climate_friendly" @change="toggleClimateFriendly" />
                              <setting-toggle title="Climate compensated" :status="climate_compensated" @change="toggleClimateCompensated" />
                            </settings-group>
                          </div>

                          <div class="mt-3">
                            <settings-group title="Discoverability" icon="far fa-eye fa-fw">
                              <setting-toggle
                                title="Status"
                                description="Disable to set all statuses and variations to inactive"
                                :status="status"
                                @change="toggleStatus"
                              />
                              <setting-toggle
                                title="Listable"
                                description="Enable to show product on shop and in categories"
                                :status="is_listable"
                                @change="toggleListable"
                              />
<!--                              <setting-toggle title="Topp 20" :status="is_top_20" @change="togglePopular" />-->
                              <setting-toggle title="Promoted" :status="is_promoted" @change="togglePromoted" />
                              <setting-toggle
                                  title="Excluded from the feed"
                                  description="Enable to exclude product from the Meta feed file"
                                  :status="is_excluded_from_feed"
                                  @change="toggleExcludedFromFeed"
                              />
                            </settings-group>
                          </div>

                          <h4 class="mt-3"><i class="far fa-heat fa-fw" /> Trends</h4>
                          <b-card>
                            <b-form-group label="Pulse" label-for="formPulseField">
                              <b-form-spinbutton
                                id="formPulseField"
                                v-model="pulse"
                                min="1"
                                max="3"
                                :disabled="$cannot('product.create')"
                              />
                            </b-form-group>

                            <validation-provider vid="heat" name="Heat" v-slot="context" rules="required">
                              <b-form-group label="Heat" label-for="formHeatField">
                                <b-form-input
                                  type="text"
                                  id="formHeatField"
                                  v-model="heat"
                                  :state="getValidationState(context)"
                                  :disabled="$cannot('product.create')"
                                  aria-describedby="formHeatFieldError"
                                  v-bind="context.ariaInput"
                                />

                                <b-form-invalid-feedback id="formHeatFieldError" v-bind="context.ariaMsg">{{
                                  context.errors[0]
                                }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-card>

                          <h4 class="mt-3"><i class="far fa-eye fa-fw" /> Main Category</h4>
                          <b-card>
                            <validation-provider vid="main_category_id" name="Main Category" v-slot="context" rules="required">
                              <multi-select
                                v-model="main_category"
                                :options="mainCategories"
                                :preserve-search="true"
                                :disabled="$cannot('product.create')"
                                placeholder="Choose main category"
                                select-label=""
                                deselect-label=""
                                selected-label=""
                                label="name"
                                track-by="id"
                                @input="() => selectedSubCategories = []"
                              />

                              <div class="text-red" v-if="context.errors.length">
                                {{ context.errors[0] }}
                              </div>

                              <b-form-invalid-feedback id="formMainCategoryFieldError" v-bind="context.ariaMsg">
                                {{context.errors[0]}}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-card>

                          <h4 class="mt-3"><i class="far fa-eye fa-fw" /> Sub categories</h4>
                          <b-card>
                            <validation-provider vid="sub_categories" name="Sub categories" v-slot="context" rules="required">
                              <multi-select
                                v-model="selectedSubCategories"
                                :options="subCategories"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                select-label=""
                                deselect-label=""
                                selected-label=""
                                :disabled="$cannot('product.create') || !main_category"
                                placeholder="Choose sub-categories"
                                label="name"
                                track-by="id"
                              />

                              <div class="text-red" v-if="context.errors.length">
                                {{ context.errors[0] }}
                              </div>

                              <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{context.errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-card>

                          <h4 class="mt-3"><i class="far fa-eye fa-fw" /> Additional categories</h4>
                          <b-card>
                            <validation-provider vid="additional_categories" name="Additional categories" v-slot="context" rules="required">
                              <multi-select
                                v-model="selectedAdditionalCategories"
                                :options="additionalCategories"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                select-label=""
                                deselect-label=""
                                selected-label=""
                                :disabled="$cannot('product.create')"
                                placeholder="Choose additional categories"
                                label="name"
                                track-by="id"
                              />

                              <div class="text-red" v-if="context.errors.length">
                                {{ context.errors[0] }}
                              </div>

                              <b-form-invalid-feedback v-bind="context.ariaMsg">
                                {{context.errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-card>

                          <h4 class="mt-3"><i class="far fa-eye fa-fw" /> Tags</h4>
                          <b-card>
                            <b-form-group>
                              <multi-select
                                v-model="tags"
                                :options="availableTags"
                                :multiple="true"
                                :taggable="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                placeholder="Choose tags"
                                @tag="addTag"
                                @search-change="searchTags"
                              />
                            </b-form-group>
                          </b-card>
                        </b-col>
                      </b-row>
                    </form>
                  </validation-observer>
                </b-tab>
                <b-tab
                  title="Connections"
                  :active="
                    $route.name === 'products.edit.variations' ||
                      $route.name === 'product.variations.edit' ||
                      $route.name === 'product.variations.new'
                  "
                  :disabled="!isEditPage"
                  lazy
                  @click="tabGoTo(links.variations)"
                >
                  <product-variation v-if="$route.name === 'products.edit.variations'" />
                  <products-variation-form v-else />
                </b-tab>
                <b-tab
                  title="Images"
                  :active="$route.name === 'products.edit.images'"
                  :disabled="!isEditPage"
                  lazy
                  @click="tabGoTo(links.images)"
                >
                  <product-images :product="product" @updated="loadContent" />
                </b-tab>
                <b-tab
                  title="Reviews"
                  :active="$route.name === 'products.edit.reviews'"
                  :disabled="!isEditPage"
                  lazy
                  @click="tabGoTo(links.reviews)"
                >
                  <product-reviews :product="product" />
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'

import Draggable from 'vuedraggable'
import MultiSelect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import SettingsGroup from '../../components/SettingsGroup'
import SettingToggle from '../../components/SettingToggle'
import ProductVariation from './ProductsVariation'
import ProductImages from './ProductImages'
import ProductReviews from './ProductReviews'
import { getValidationState } from '../page-helpers'
import { tags as productTag } from '@/api'
import Froala from '@/components/Froala.vue'
import ProductsVariationForm from '@/pages/Products/ProductsVariationForm'

export default {
  name: 'ProductsFormPage',
  inject: ['$cannot'],
  components: {
    Draggable,
    SettingsGroup,
    SettingToggle,
    MultiSelect,
    ProductVariation,
    ProductImages,
    ProductReviews,
    Froala,
    ProductsVariationForm,
  },
  data() {
    return {
      availableTags: [],
      type: 'product',
      product: null,
      thumbnail: null,
      main_category: null,
      selectedSubCategories: [],
      selectedAdditionalCategories: [],
      bullet_points: null,
      hotel_map_only: false,
      hotel_count: null,
      hotel_content: [],
      experience_cash_voucher_data: [],
      experience_cash_voucher_description: null,
      experience_cash_voucher_amount_description: null,
      content: '',
      heat: 10000,
      id: '',
      status: false,
      is_listable: false,
      is_popular: false,
      is_top_20: false,
      is_promoted: false,
      climate_friendly: false,
      climate_compensated: false,
      is_excluded_from_feed: false,
      lead: '',
      name: '',
      pulse: 1,
      slug: '',
      video_content: '',
      how_it_works: '',
      tags: [],
      shopping_list: [{ name: '', price: null }],
      downloadable_shopping_list: [{ name: '', price: null, quantity: 1, custom_content: '' }],
      what_is_learned_title: '',
      what_is_learned: [{ title: '' }],
      whats_included: [{ title: '', icon: 'access' }],
      participants_text: '',
      when: '',
      duration: '',
      requirements: '',
      min_age: 3,
      voucher_description_text: '',
      seo: {
        meta_title: '',
        meta_description: '',
        no_index: false,
      },
      isLoading: false,
    }
  },
  beforeRouteEnter(from, to, next) {
    next(vm => vm.loadContent())
  },
  computed: {
    ...mapGetters({
      allCategories: 'base/categories',
    }),
    isEditPage() {
      return this.$route.name !== 'products.new'
    },
    pageTitle() {
      return this.isEditPage ? 'Edit Product' : 'New Product'
    },
    breadcrumbTitle() {
      return this.isEditPage ? 'EDIT' : 'NEW'
    },
    buttonText() {
      return `${this.isEditPage ? 'Save' : 'Create'} Product`
    },

    mainCategories() {
      const categories = this.allCategories.filter(c => c.type === 'category' && c.parent_id === null);

      categories.unshift({name: 'Choose a main category', $isDisabled: true})

      return categories;
    },

    subCategories() {
      const categories = this.allCategories.filter(c => c.type === 'category' && c.parent_id);

      categories.unshift({name: 'Choose multiple sub-categories', $isDisabled: true})

      return categories;
    },

    additionalCategories() {
      const categories = this.allCategories.filter(c => c.type === 'gifttips');

      categories.unshift({name: 'Choose multiple additional categories', $isDisabled: true})

      return categories;
    },

    links() {
      const id = this.$route.params.id

      return {
        edit: { name: 'products.edit', params: { id } },
        variations: { name: 'products.edit.variations', params: { id } },
        images: { name: 'products.edit.images', params: { id } },
        reviews: {
          name: 'products.edit.reviews',
          params: { id },
          query: { tab: 'pending' },
        },
      }
    },
    defaultShoppingList() {
      return { name: '', price: null }
    },
    defaultWhatIsLearned() {
      return { title: '' }
    },
    defaultWhatsIncluded() {
      return { title: '', icon: 'access' }
    },
    whatsIncludedIcons() {
      return [
        'access',
        'alarm',
        'certificate',
        'chat',
        'devices',
        'download',
        'faq',
        'id',
        'list',
        'live',
        'material',
        'moderator',
        'repeat',
        'shoppinglist',
        'star',
        'trophy',
        'video',
      ]
    },
  },
  methods: {
    getValidationState,
    async loadContent() {
      if (!this.isEditPage) return

      const product = await this.$store.dispatch('products/find', this.$route.params.id)

      this.product = product

      this.type = product.product_type ?? 'product'
      this.bullet_points = product.bullet_points ?? null
      this.hotel_content = product.hotel_content ?? null
      this.experience_cash_voucher_data = product.experience_cash_voucher_data ?? null
      this.experience_cash_voucher_description = product.experience_cash_voucher_description ?? null
      this.experience_cash_voucher_amount_description = product.experience_cash_voucher_amount_description ?? null
      this.experience_cash_voucher_error_message = product.experience_cash_voucher_error_message ?? null
      this.hotel_count = product.hotel_count ?? null
      this.hotel_map_only = product.hotel_map_only ?? false
      this.content = product.content
      this.heat = product.heat
      this.id = product.id
      this.is_listable = product.is_listable
      this.is_top_20 = product.is_top_20
      this.is_promoted = product.is_promoted
      this.climate_friendly = product.climate_friendly
      this.climate_compensated = product.climate_compensated
      this.is_excluded_from_feed = product.is_excluded_from_feed
      this.lead = product.lead
      this.main_category = product.main_category
      this.selectedSubCategories = product.categories.filter(c => c.type === 'category')
      this.selectedAdditionalCategories = product.categories.filter(c => c.type === 'gifttips')
      this.name = product.name
      this.slug = product.slug
      this.pulse = product.pulse
      this.status = product.status
      this.thumbnail = product.thumbnail
      this.tags = product.tags
      this.video_content = product.video_content
      this.how_it_works = product.how_it_works
      this.shopping_list = product.shopping_list ?? []
      this.downloadable_shopping_list = product.downloadable_shopping_list ?? []
      this.what_is_learned_title = product.what_is_learned_title
      this.what_is_learned = product.what_is_learned ?? []
      this.whats_included = product.whats_included ?? []
      this.participants_text = product.participants_text
      this.when = product.when
      this.duration = product.duration
      this.requirements = product.requirements
      this.min_age = product.min_age
      this.voucher_description_text = product.voucher_description_text
      this.seo = product.seo
    },
    tabGoTo(route) {
      if (route.name !== this.$route.name) {
        this.$router.push(route)
      }
    },
    pick(event, index) {
      const file = event.target.files[0];
      if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
              this.hotel_content[index].image = e.target.result;
          };
      }
    },
    addTag(newTag) {
      this.availableTags.push(newTag)
      this.tags.push(newTag)
    },
    async searchTags(query) {
      const tags = await productTag.all({
        filter: {
          name: query,
        },
      })

      this.availableTags = tags.data().map(t => t.name)
    },
    toggleStatus() {
      this.status = !this.status
    },
    toggleListable(status) {
      this.is_listable = status
    },
    togglePopular(status) {
      this.is_top_20 = status
    },
    togglePromoted(status) {
      this.is_promoted = status
    },
    toggleClimateFriendly(status) {
      this.climate_friendly = status
    },
    toggleClimateCompensated(status) {
      this.climate_compensated = status
    },
    toggleExcludedFromFeed(status) {
      this.is_excluded_from_feed = status
    },
    async submitFormData() {
      const data = {
        main_category_id: this.main_category ? this.main_category.id : null,
        bullet_points: this.bullet_points,
        hotel_content: this.hotel_content,
        experience_cash_voucher_data: this.experience_cash_voucher_data,
        experience_cash_voucher_description: this.experience_cash_voucher_description,
        experience_cash_voucher_amount_description: this.experience_cash_voucher_amount_description,
        experience_cash_voucher_error_message: this.experience_cash_voucher_error_message,
        hotel_count: this.hotel_count,
        hotel_map_only: this.hotel_map_only,
        content: this.content,
        heat: +this.heat,
        status: this.status,
        is_listable: this.is_listable,
        is_top_20: this.is_top_20,
        is_promoted: this.is_promoted,
        climate_friendly: this.climate_friendly,
        climate_compensated: this.climate_compensated,
        is_excluded_from_feed: this.is_excluded_from_feed,
        lead: this.lead,
        name: this.name,
        pulse: this.pulse,
        slug: this.slug,
        video_content: this.video_content,
        sub_categories: this.selectedSubCategories.length ? this.selectedSubCategories.map(c => c.id) : null,
        additional_categories: this.selectedAdditionalCategories.length ? this.selectedAdditionalCategories.map(c => c.id) : null,
        how_it_works: this.how_it_works,
        shopping_list: this.shopping_list.length ? this.shopping_list : null,
        downloadable_shopping_list: this.downloadable_shopping_list.length ? this.downloadable_shopping_list : null,
        what_is_learned_title: this.what_is_learned_title,
        what_is_learned: this.what_is_learned.length ? this.what_is_learned : null,
        whats_included: this.whats_included.length ? this.whats_included : null,
        participants_text: this.participants_text,
        when: this.when,
        tags: this.tags.length ? this.tags : null,
        duration: this.duration,
        requirements: this.requirements,
        min_age: this.min_age,
        voucher_description_text: this.voucher_description_text,
        seo: this.seo,
        product_type: this.type,
      }

      this.isLoading = true
      if (this.isEditPage) {
        //await products.update(this.id, data)
        this.handleProductUpdate({ ...data, id: this.id })
      } else {
        this.handleProductCreation(data)
      }
    },
    async handleProductCreation(payload) {
      try {
        const product = await this.$store.dispatch('products/create', payload)
        this.$router.push({ name: 'products.edit', params: { id: product.data.id } })
        this.$bvToast.toast(`Product "${this.name}" created.`, {
          title: 'Product Created',
          variant: 'primary',
        })
      } catch (error) {
        this.$refs.productsForm.setErrors(error.errors)
      } finally {
        this.isLoading = false
      }
    },
    async handleProductUpdate(payload) {
      try {
        await this.$store.dispatch('products/update', payload)
        this.$bvToast.toast(`Product "${this.name}" updated.`, {
          title: 'Product Updated',
          variant: 'primary',
        })
      } catch (error) {
        this.$refs.productsForm.setErrors(error.errors)
        this.$bvToast.toast(`There where validation errors.`, {
          title: 'Product could not be updated',
          variant: 'danger',
        })
      } finally {
        this.isLoading = false
      }
    },
    removeBulletPoint(index) {
      if (this.bullet_points.length === 1) {
        return (this.bullet_points = null)
      }

      this.bullet_points.splice(index, 1)
    },
    addEmptyBulletPoint() {
      this.bullet_points ? this.bullet_points.push({ content: '' }) : this.bullet_points = [{ content: '' }]
    },
    addEmptyHotelContent() {
      this.hotel_content ? this.hotel_content.push({ content: '' }) : this.hotel_content = [{ content: '' }]
    },
    addExperienceCashVoucherData() {
      this.experience_cash_voucher_data ? this.experience_cash_voucher_data.push({ name: '' }) : this.experience_cash_voucher_data = [{ name: '' }]
    },
    removeHotelContent(index) {
      if (this.hotel_content.length === 1) {
          return (this.hotel_content = null)
      }

      this.hotel_content.splice(index, 1)
    },
    removeExperienceCashVoucherData(index) {
      if (this.experience_cash_voucher_data.length === 1) {
          return (this.experience_cash_voucher_data = null)
      }

      this.experience_cash_voucher_data.splice(index, 1)
    },
    addListItem(target, content) {
      target.push(content)
    },
    removeListItem(target, index, content) {
      if (target.length === 1) {
        return this.addListItem(target, content)
      }

      target.splice(index, 1)
    },
    getIcon(id) {
      return `/img/icon/product_included/${id}.svg`
    },
    updateWhatLearnedIcon(index, icon) {
      const data = [...this.whats_included]
      data[index].icon = icon

      this.$set(this.whats_included, data)
    },
  },
}
</script>
